import { Component, Inject, NgModule, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoUniversoService } from 'src/app/services/tipo-universo.service';
import { ITipoUniversoItemModel, ITipoUniversoModel } from 'src/app/models/tipo-universo.model';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ITipoUniversoUGModel } from 'src/app/models/tipo-universo.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ModalTipoUniversoItemDescricaoComponent } from './modal-tipo-universo-item-descricao/modal-tipo-universo-item-descricao.component';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-modal-tipo-universo-item',
    templateUrl: './modal-tipo-universo-item.component.html',
    styleUrls: ['./modal-tipo-universo-item.component.scss']
  })
  export class ModalTipoUniversoItemComponent extends BaseFormComponent implements OnInit{
    
    public model : ITipoUniversoUGModel = {} as ITipoUniversoUGModel;
    public dataSource = new MatTableDataSource<any>([]);
    public displayedColumns = ['ug', 'valor'];
    public titulo = "Editar SubTipo de Universo Auditável";
    public tipoDescription = "";
    public nomeUG = "";
    public semDados: boolean;
    public idsUgsExistentes = [];
    public idClassificacaoTipo: number;
    public alterou: boolean;
    

    constructor(
        route: ActivatedRoute,
        router: Router,
        private dialogRef: MatDialogRef<ModalTipoUniversoItemComponent>,
        public toastr: ToastrService,
        localeService: BsLocaleService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialog: MatDialog,
        private tipoUniversoService: TipoUniversoService,
        private unidadeGestoraService: UnidadeGestoraService
    
      ) { 
        super(route, toastr, router, localeService, matDialog); 

        
        this.model.idTipoUniversoAuditavel = data.idTipoUniverso;
        this.idClassificacaoTipo = data.idClassificacaoTipo;
        this.tipoDescription = data.tipoDescription;
        this.nomeUG = data.nomeUG;
        this.visualizar = data.visualizar;
        this.model.idUG = data.idUG;
        if(this.data.idsUgsExistentes){
          this.idsUgsExistentes = this.data.idsUgsExistentes;
        }
        
        if(!data.idUG && this.idClassificacaoTipo == 2){
          this.titulo = "Incluir SubTipo de Universo Auditável";
        }

        if(this.visualizar){
          this.titulo = 'Visualizar SubTipo de Universo Auditável';
        }

        this.buscarItens(data.idTipoUniverso, data.idUG);
      }

async ngOnInit() {
    
}

public async buscarItens(idTipoUniverso: number, idUG: number){

  if(idTipoUniverso){

    const res = await this.tipoUniversoService.obterItens(idTipoUniverso, idUG);
    if(res.sucesso){
        this.model = res.dados;
        this.semDados = this.model.itens.length == 0;
        if(this.semDados){
          this.novoRegistro = true;
        }else{
          this.novoRegistro = false;
        }
        this.reordenar();
    }else{
      this.exibirMensagemErro(res.mensagem.descricao);
      this.dialogRef.close();
    }

  }

}

public async concluir(salvarEFechar: boolean){
  this.submit = true;

  if(this.novoRegistro){
    
    if(!this.model.itens || this.model.itens.length == 0){
      this.exibirMensagemErro("É necessário incluir pelo menos 1 subtipo para salvar.")
      return;
    }

    if(this.idClassificacaoTipo == 2 && !this.model.idUG){
      return;
    }

    this.tipoUniversoService.inserirItens(this.model)
    .then((res) => {
      if(res.sucesso){
        this.exibirMensagemSucesso(res.mensagem.descricao);
        this.alterou = false;
        if(salvarEFechar){
          this.dialogRef.close(true);
        }
      }else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    }).catch(err => this.exibirMensagemErro(err))
    
  }else{

    this.tipoUniversoService.atualizarItens(this.model)
    .then((res) => {
      if(res.sucesso){
        this.exibirMensagemSucesso(res.mensagem.descricao);
        this.alterou = false;
        if(salvarEFechar){
          this.dialogRef.close(null);
        }
        
      }else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    }).catch(err => this.exibirMensagemErro(err))

  }

}

public drop(event: CdkDragDrop<string[]>, element: any) {

}

public retornaItensArray(): ITipoUniversoItemModel[]{
  let itens= [] as ITipoUniversoItemModel[];

  this.model.itens.forEach(item => {
    itens.push(item);
    if(item.itens && item.itens.length > 0){
      item.itens.forEach(subItem => {
        itens.push(subItem);
        if(subItem.itens && subItem.itens.length > 0){
          subItem.itens.forEach(subSubItem => {
            itens.push(subSubItem);
          })
        }
      });
    }
  });

  return itens;
}

public reordenar(){
  this.model.itens.forEach((item, idx) => {
    item.ordem = (idx + 1);
    item.ordemExibicao = `1.${item.ordem.toString()}`;
    if(item.itens && item.itens.length > 0){
      this.fnReordenacao(item);
    }
  });
}

public fnReordenacao(item: ITipoUniversoItemModel){
  item.itens.forEach((subItem, subIdx) => {
    
    subItem.ordem = subIdx + 1;
    subItem.ordemExibicao = `${item.ordemExibicao}.${subItem.ordem.toString()}`
    if(subItem.itens && subItem.itens.length > 0){
      this.fnReordenacao(subItem);
    }
  });
}

public exibirModalItemNovo(element: any, indx: string){


let item = {} as ITipoUniversoItemModel;
item.id = element?.id ?? 0;
item.idItemPai = element?.idItemPai ?? 0;
item.idTipoUniversoAuditavel = element?.idTipoUniversoAuditavel ?? this.model.idTipoUniversoAuditavel;
item.idUG = element?.idUG ?? this.model.idUG;
item.nome = element?.nome ?? "";
item.tipo = element?.tipo ?? 1;

let arrayIndices = JSON.parse("[" + indx + "]");

if(arrayIndices.length == 1 && arrayIndices[0] == null){
  
  item.tipo = 1;
  item.tipoDescription = element?.tipoDescription ?? "SubTipo";

}else if(arrayIndices.length == 1 && arrayIndices[0] != null){
  
  item.tipo = 2;
  item.tipoDescription = element?.tipoDescription ?? "Macroprocesso";

}else if(arrayIndices.length == 2){

  item.tipo = 3;
  item.tipoDescription = element?.tipoDescription ?? "Processo";

}


  const dialogConfig = new MatDialogConfig();
  dialogConfig.id = 'modal-descricao';
  dialogConfig.width = '450px';
  dialogConfig.hasBackdrop = true;
  dialogConfig.disableClose = true;
  dialogConfig.data = {
            item: item,
            indx: indx
  };

  const modal = this.matDialog.open(ModalTipoUniversoItemDescricaoComponent, dialogConfig);

  modal.afterClosed().subscribe((data) => {
    if(data){
      
      let novoItem = data.item as ITipoUniversoItemModel;

      //NÃO HAVENDO ÍNDICE, ADICIONA-SE NO ITEM RAIZ
      if(!data.indx){

        if(this.existeNomeMesmoNivel(this.model.itens, novoItem.nome)){
          this.exibirMensagemErro("Já existe SubTipo com este nome.");
          return;
        }

        novoItem.tipo  = 1;
        novoItem.tipoDescription="SubTipo";
        if(!this.model.itens){
          this.model.itens = [] as ITipoUniversoItemModel[];
        }
        this.model.itens.push(novoItem);
        this.alterou = true;
        this.reordenar();
        return;
      }

      //indx recebe os índices da hierarquia da árvore: 
      //0 -> nível 1
      //0,0 -> nível 2 
      //0,0,0 -> nível 3 

      let arrayIndices = JSON.parse("[" + data.indx + "]");
      
      if(arrayIndices.length == 1){

        if(this.existeNomeMesmoNivel(this.model.itens[arrayIndices[0]].itens, novoItem.nome)){
          this.exibirMensagemErro("Já existe Macroprocesso com este nome.");
          return;
        }

        novoItem.tipo = 2;
        novoItem.tipoDescription = "Macroprocesso";

        if(!this.model.itens[arrayIndices[0]].itens){
          this.model.itens[arrayIndices[0]].itens = [] as ITipoUniversoItemModel[];
        }

        this.model.itens[arrayIndices[0]].itens.push(novoItem);
        
      }else if(arrayIndices.length == 2){
        
        if(this.existeNomeMesmoNivel(this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens, novoItem.nome)){
          this.exibirMensagemErro("Já existe Macroprocesso com este nome.");
          return;
        }

        novoItem.tipo = 3;
        novoItem.tipoDescription = "Processo";
        
        if(!this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens){
          this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens = [] as ITipoUniversoItemModel[];
        }

        this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens.push(novoItem);

      }

      this.alterou = true;
      this.reordenar();

    }
  })

}

private existeNomeMesmoNivel(itens: ITipoUniversoItemModel[], nome: string){
  return itens && itens.find(item => item.nome.toUpperCase() == nome.toUpperCase());
}

public exibirModalItemEditar(element: ITipoUniversoItemModel, indx: string){
  
  const dialogConfig = new MatDialogConfig();
  dialogConfig.id = 'modal-descricao';
  dialogConfig.width = '450px';
  dialogConfig.hasBackdrop = true;
  dialogConfig.disableClose = true;
  dialogConfig.data = {
            item: element,
            indx: indx
  };

  const modal = this.matDialog.open(ModalTipoUniversoItemDescricaoComponent, dialogConfig);

  modal.afterClosed().subscribe((data) => {
  
    let novoItem = data.item as ITipoUniversoItemModel;
    let arrayIndices = JSON.parse("[" + data.indx + "]");

    if(arrayIndices.length == 1){

      this.model.itens[arrayIndices[0]].nome = novoItem.nome;
      
    }else if(arrayIndices.length == 2){

      this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].nome = novoItem.nome;
      
    }else if(arrayIndices.length == 2){
      
      this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens[arrayIndices[2]].nome = novoItem.nome;

    }

    this.alterou = true;

  })

}

public excluirItem(element: any, indx: string){

  Swal.fire({
    title: 'Excluir item',
    text: `Deseja realmente excluir este item?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then(resConfirm => {
    if (resConfirm.isConfirmed) {

      let arrayIndices = JSON.parse("[" + indx + "]");

      if(arrayIndices.length == 1){
        this.model.itens.splice(arrayIndices[0], 1);
      }else if(arrayIndices.length == 2){
        this.model.itens[arrayIndices[0]].itens.splice(arrayIndices[1], 1);
      }else if (arrayIndices.length == 3){
        this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens.splice(arrayIndices[2], 1);
      }
    
      this.alterou = true;
      this.reordenar();

    }else{
    }
  });

 
}

public async exibirModalUnidadeGestora(){
  
  let filtro = {} as FiltroModel;
  filtro.itensPorPagina = 999;
  filtro.ativo = true;
  const res = await this.unidadeGestoraService.obter(filtro);
      if (res.sucesso) {
        const customFilterPredicate = (data: { sigla: string, nome: string }, filterValue: string) => {
          return (data.sigla || '').trim().toLowerCase().indexOf(filterValue) !== -1
            || (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
        };

        const modal = this.exibirModal('Selecionar Unidade Gestora', res.dados.resultado.filter(_ => !this.idsUgsExistentes.find(i => i === _.id)).map(c => ({ ...c, sigla: c.sigla })), false, [
          {
            columnDef: 'selecao',
            header: ''
          },
          {
            columnDef: 'sigla',
            header: 'Sigla'
          },
          {
            columnDef: 'nome',
            header: 'Nome'
          }], customFilterPredicate);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.alterou = true;
            this.model.idUG = data.id;
            this.nomeUG = data.nome;
            this.submit = false;
          }
        }
        );
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
}

public moveUp(element: any, indx: string){
  let arrayIndices = JSON.parse("[" + indx + "]");

  if(arrayIndices.length == 1){
    
    if((arrayIndices[0]) == 0)
      return;

    moveItemInArray(this.model.itens, arrayIndices[0], arrayIndices[0]-1)
    
  }else if(arrayIndices.length == 2){
    
    if((arrayIndices[1]) == 0)
      return;

    moveItemInArray(this.model.itens[arrayIndices[0]].itens, arrayIndices[1], arrayIndices[1]-1)

  }else{
    if((arrayIndices[2]) == 0)
      return;

    moveItemInArray(this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens, arrayIndices[2], arrayIndices[2]-1)
  }

  this.alterou = true;
  this.reordenar();
}

public moveDown(element: any, indx: string){

  let arrayIndices = JSON.parse("[" + indx + "]");

  if(arrayIndices.length == 1){
    
    if((arrayIndices[0]) == this.model.itens.length -1)
      return;

    moveItemInArray(this.model.itens, arrayIndices[0], arrayIndices[0]+1)
    
  }else if(arrayIndices.length == 2){
    
    if((arrayIndices[1]) == this.model.itens[arrayIndices[0]].itens.length -1)
      return;

    moveItemInArray(this.model.itens[arrayIndices[0]].itens, arrayIndices[1], arrayIndices[1]+1)

  }else{
    if((arrayIndices[2]) == this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens.length -1)
      return;

    moveItemInArray(this.model.itens[arrayIndices[0]].itens[arrayIndices[1]].itens, arrayIndices[2], arrayIndices[2]+1)
  }

  this.alterou = true;
  this.reordenar();

}

  public cancelar() {

    if(this.alterou){
      const dialogConfig = new MatDialogConfig();
      
      const modal = this.matDialog.open(ModalCancelarComponent, dialogConfig);
      modal.afterClosed().subscribe((resultado) => {
        if (resultado == true) {
          this.dialogRef.close(true);
        }
      });
    }else{
      this.dialogRef.close(true);
    }
    
  }

}

  